/* global Ya, uuidv4 */

import '@yandex-int/error-counter/dist/implementation.js';
import '@yandex-int/error-counter/dist/interfaceOverRum.js';
import '@yandex-int/error-counter/dist/filters.js';
import '@yandex-int/error-counter/dist/logError.js';

Ya.Rum.initErrors({
    reqid: uuidv4(),
    project: 'vconf',
    page: 'index',
    platform: 'desktop',
});
